import { useContext, useEffect } from 'react';
import { GlobalContext } from "../../contexts/globalContext";
import ProjectComponent from './ProjectComponent';
import PlusIcon from "../../graphics/plus.svg";
import AddProjectPopup from '../popups/AddProjectPopup';
import styles from './ProjectsComponent.module.css';

const ProjectsComponent = () => {
    const { projects, setShowAddProjectCompooent, showAddProjectCompooent, socketRef } = useContext(GlobalContext);

    // useEffect(() => {
    //     console.log("PROJECTS_OPEN");
    //     socketRef.current.emit("PROJECTS_OPEN");
    // }, []);

    return (
        <div className={styles.component}>
            <ul className={styles.projects_list}>
                {projects && projects.map((project) => (                    
                    <ProjectComponent key={project._id} project={project}></ProjectComponent>
                ))}
            </ul>
            <div className={styles.add_button} onClick={()=> setShowAddProjectCompooent(true)}>
                <img src={PlusIcon} width={60} height={60}/>
            </div>

            {showAddProjectCompooent && <AddProjectPopup onClose={()=>setShowAddProjectCompooent(false)} />}
        </div>
    );
}

export default ProjectsComponent;
