import React, { useRef, useState, useContext, useEffect } from 'react';
import { GlobalContext } from "../../contexts/globalContext";
import styles from './styles.module.css';

const AddFilesPopup = ({ document, onClose }) => {
    const ref = useRef();
    const inputNameRef = useRef();
    const inputFile = useRef();
    const [fileData, setFileData] = useState(null);
    const [docId, setDocId] = useState(null);
    const [fileName, setFileName] = useState('');
    const [showFileInput, setShowFileInput] = useState(true);
    const { currentUser, socketRef, documentsFilter, currentProject } = useContext(GlobalContext);

    const allowedTypes = () => {
        switch (documentsFilter) {
            case "doc":
                return ".docx, .doc";
            case "sheet":
                return ".xlsx, .xls";
            case "image":
                return ".jpg, .jpeg, .png, .gif, .svg";
            case "video":
                return ".mp4, .avi, .webm, .mpeg";
            case "pdf":
                return ".pdf";
            default:
                return ".docx, .doc, .xlsx, .xls, .jpg, .jpeg, .png, .gif, .svg, .mp4, .avi, .webm, .mpeg, .pdf";
        }
    }

    useEffect(() => {
        if (document) {
            setFileName(document.name);
            setDocId(document._id);
            setShowFileInput(false);
        }
    }, [document]);

    function handleFileChange(event) {
        const file = event.target.files[0];
        setFileData(file);
    }

    function addDocument() {
        if (!fileName || (!document && !fileData)) {
            alert('Please fill in all required fields.');
            return;
        }

        const type = fileData ? fileData.type : null;

        const documentDetails = {
            ownerId: currentUser._id,
            uploadFile: fileData,
            type,
            fileName,
            docId
        };

        socketRef.current.emit("ADD_EDIT_DOCUMENT", documentDetails, (response) => {
            console.log("ADD_EDIT_DOCUMENT response:", response);
            if (response && response._id) {
                if (currentProject) {
                    const docs = [response._id];
                    socketRef.current.emit("ADD_EXISTING_PROJECT_DOCUMENTS", { docIds: docs, userId: currentUser._id, projectId: currentProject._id }, (projectResponse) => {
                        onClose();
                    });
                } else {
                    onClose();
                }
            } else {
                alert('Failed to add or edit document.');
            }
        });
    }

    return (
        <div className={styles.modal}>
            <div className={styles.overlay}>
                <div className={styles.modalContent}>
                    <title> {document ? 'Edit file' : 'Add file'} </title>
                    <input name="name" value={fileName} placeholder='File name' onChange={(e) => setFileName(e.target.value)} required />
                    {!document && (
                        <input name="browseFile" type="file" accept={allowedTypes()} onChange={handleFileChange} required />
                    )}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <button onClick={() => addDocument()} className={styles.btn} type='submit'> Save </button>
                        <button onClick={onClose} className={styles.btn}> Close </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddFilesPopup;
