import React, { useRef, useState, useContext, useEffect } from 'react';
import { GlobalContext } from "../../contexts/globalContext";
import styles from './styles.module.css';

const AddProjectPopup = ({ onClose }) => {
    const [projectName, setProjectName] = useState('');

    const { currentUser, socketRef } = useContext(GlobalContext);



    function saveProject() {
        if (!projectName) {
            console.error("File name is required.");
            return;
        }

        socketRef.current.emit("ADD_EDIT_PROJECT", { ownerId: currentUser._id, newData: {name:projectName}}, (response) => {
            onClose();
            console.log(response)
        });
    }

    

    return (
        <div className={styles.modal}>
            <div className={styles.overlay}>
                <div className={styles.modalContent}>
                    <title> asdasd </title>
                    <input name="name" value={projectName} placeholder='Project name' onChange={(e) => setProjectName(e.target.value)} required />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <button onClick={() => saveProject()} className={styles.btn} type='submit'> Save </button>
                        <button onClick={onClose} className={styles.btn}> Close </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddProjectPopup;
