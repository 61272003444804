import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from "../../../contexts/globalContext";
import styles from '../styles.module.css';

const SelectParticipantPopup = ({ onClose, onCloseMain }) => {
    const { socketRef, currentProject } = useContext(GlobalContext);
    const [participants, setParticipants] = useState([]);
    const [selectedParticipants, setSelectedParticipants] = useState([]);

    useEffect(() => {
        socketRef.current.emit("GET_PARTICIPANTS_TO_ADD_PROJECT", currentProject._id, (response) => {
            setParticipants(response)
        });
    });

    const handleParticipantSelection = (participantId) => {
        setSelectedParticipants((prevSelected) => {
            if (prevSelected.includes(participantId)) {
                return prevSelected.filter(id => id !== participantId);
            } else {
                return [...prevSelected, participantId];
            }
        });
    };

    const handleSave = () => {
        socketRef.current.emit("ADD_PARTICIPANTS_TO_PROJECT", { projectId: currentProject._id, participantIds: selectedParticipants })
        onClose();
        onCloseMain();
    };

    return (
        <div className={styles.modal}>
            <div className={styles.overlay}>
                <div className={styles.modalContent}>
                    <h2>Select participants</h2>
                    <div className={styles.fileList}>
                        {participants.map((participant) => (
                            <div key={participant._id} className={styles.fileItem}>
                                <input
                                    type="checkbox"
                                    checked={selectedParticipants.includes(participant._id)} P
                                    onChange={() => handleParticipantSelection(participant._id)}
                                />
                                <span>{participant.name}</span>
                            </div>
                        ))}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '20px' }}>
                        <button onClick={handleSave} className={styles.btn}>Save</button>
                        <button onClick={onClose} className={styles.btn}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectParticipantPopup;
