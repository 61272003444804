import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from "../contexts/globalContext";
import styles from "./MenuComponent.module.css";

const MenuComponent = ({ setTitle }) => {

    const { setProjects, setDocumentsFilter, documentsFilter, socketRef, currentUser, setDocuments, setPendingDocuments, setIsOpenedProjects, setShowEditProjectComponent, setShowEditNoteComponent } = useContext(GlobalContext);
    const [selectedButton, setSelectedButton] = useState("Projects");

    const filters = [
        { name: "Documents", type: "doc" },
        { name: "Sheets", type: "sheet" },
        { name: "Videos", type: "video" },
        { name: "Images", type: "image" },
        { name: "PDF", type: "pdf" },
    ];

    useEffect(() => {
        if (documentsFilter) {
            socketRef.current.emit("GET_USER_DOCUMENTS", { userId: currentUser._id, filter: documentsFilter }, (documents) => {
                setDocuments(documents);                
            });
        }
    }, [documentsFilter])

    const ChangeDocuments = (filter) => {
        setDocumentsFilter(filter.type);
        setIsOpenedProjects(false);
        setTitle(filter.name.toUpperCase());
        setSelectedButton(filter.name);

        setShowEditProjectComponent(false);
        setShowEditNoteComponent(false);
    }

    const onClickProjects = () => {
        if (documentsFilter) {
            console.log("GET_ALL_PROJECTS");
            socketRef.current.emit("GET_ALL_PROJECTS", currentUser._id, true, null, (projects) => {
                setProjects(projects)
            });


            setDocumentsFilter(null);
            setIsOpenedProjects(true);
            setTitle("PROJECTS");
            setSelectedButton("Projects");
        }
    }

    return (
        <div>
            {filters.map((filter) => (
                <button className={styles.button} key={filter.type} style={{ fontWeight: `${selectedButton == filter.name ? "bold" : "normal"}` }} onClick={() => ChangeDocuments(filter)}>{filter.name}</button>
            ))}
            <button className={styles.button} style={{ fontWeight: `${selectedButton == "Projects" ? "bold" : "normal"}`, marginLeft:"100px" }} onClick={onClickProjects}>Projects</button>
        </div>
    );
}

export default MenuComponent;