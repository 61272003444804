export function ApiLoginUser(body, callback) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body
    };


    fetch(process.env.REACT_APP_CMS_URL + '/api/login', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });
}

export function ApiAddDocument(body, callback, token) {


    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body,
        query: token
    };

    fetch(process.env.REACT_APP_CMS_URL + '/api/addEditDocument', requestOptions)
        .then(response => response.json())
        .then(data => {
            callback?.(data);
        });

}

