import { useContext, useState, useEffect } from 'react';
import { GlobalContext } from "../../contexts/globalContext";
import DocumentComponent from './DocumentComponent';
import PlusIcon from "../../graphics/plus.svg";
import AddFilesPopup from '../popups/AddFilesPopup';
import styles from './DocumentsComponent.module.css';

const DocumentsComponent = () => {

    const { documents,socketRef } = useContext(GlobalContext);
    const [showAddFileCompooent, setShowAddFileCompooent] = useState(false);

    // useEffect(() => {
    //     console.log("DOCUMENTS_OPEN");
    //     socketRef.current.emit("DOCUMENTS_OPEN");
    // }, []);

    return (        
        <div className={styles.component}>
            <ul className={styles.documents_list}>
                {documents && documents.map((document) => (
                    <DocumentComponent key={document._id} document={document}></DocumentComponent>
                ))}
            </ul>

            <div className={styles.plus_icon} onClick={()=> setShowAddFileCompooent(true)}>
                <img src={PlusIcon} width={60} height={60}/>
            </div>

            {showAddFileCompooent && <AddFilesPopup onClose={()=>setShowAddFileCompooent(false)} />}
        </div>
    );
}

export default DocumentsComponent;