import { useContext, useState, useRef, useEffect } from 'react';
import styles from './NoteTextComponent.module.css';

import { GlobalContext } from "../../../contexts/globalContext";




const NoteTextComponent = ({ textComponent, newText, ClearEditor }) => {

    const ref = useRef();
    const [isDraging, setIsDraging] = useState(false);
    const [text, setText] = useState("");
    const [left, setLeft] = useState(100);
    const [top, setTop] = useState(811);
    const [lastPoint, setLastPoint] = useState([]);
    const { socketRef, currentNote } = useContext(GlobalContext);

    useEffect(() => {
        setText(newText);
    }, [newText]);

    useEffect(() => {
        if (textComponent) {
            setText(textComponent.text);
            setLeft(textComponent.left);
            setTop(textComponent.top);
        }
    }, [textComponent]);

    useEffect(() => {
        if (textComponent) {
            setText(textComponent.text);
            setLeft(textComponent.left);
            setTop(textComponent.top);
        } else {
            ref.current.style.backgroundColor = "white";
            ref.current.style.borderRadius = "5px 5px 0px 0px";
        }
    }, []);


    const Down = (x, y) => {
        if (text != "") {
            setIsDraging(true);
            setLastPoint([x, y]);
            ref.current.style.backgroundColor = "white";
        }
    }

    const Move = (x, y) => {
        if (isDraging) {
            ref.current.style.borderRadius = "5px 5px 5px 5px";
            setLeft(left + x - lastPoint[0])
            setTop(top + y - lastPoint[1])
            setLastPoint([x, y]);
        }
    }

    const Up = () => {
        if (isDraging) {
            setIsDraging(false);
            if (!textComponent) {
                socketRef.current.emit("ADD_TEXT_TO_NOTE", { text, left, top, noteId: currentNote._id, id: window.crypto.randomUUID() }, () => {
                    setText("");
                    setLeft(100);
                    setTop(811);
                    ref.current.style.borderRadius = "5px 5px 0px 0px";
                    ClearEditor();
                });
            } else {
                ref.current.style.backgroundColor = "transparent";
                socketRef.current.emit("UPDATE_TEXT_NOTE", { left, top, textId: textComponent._id, noteId: currentNote._id }, () => {
                });
            }
        }
    }

    const onTouchStart= (e) => {
        Down(e.touches[0].pageX, e.touches[0].pageY);
    }

    const onMouseDown = (e) => {
        Down(e.pageX, e.pageY);
    }
    
    const onTouchMove = (e) => {
        Move(e.touches[0].pageX, e.touches[0].pageY);
    }

    const onMouseMove = (e) => {
        Move(e.pageX, e.pageY);
    }

    const onMouseUp = (e) => {
        Up();
    }

    const onTouchEnd = (e) => {
        Up();
    }

    return (
        <div className={styles.component} ref={ref}
            onTouchStart={(e) => onTouchStart(e)}
            onTouchMove={(e) => onTouchMove(e)}
            onTouchEnd={(e) => onTouchEnd(e)}

            onMouseDown={(e) => onMouseDown(e)}
            onMouseMove={(e) => onMouseMove(e)}
            onMouseUp={(e) => onMouseUp(e)}
            onMouseLeave={(e) => onMouseUp(e)}
            style={{ top: top, left: left }}
        >
            {text}
        </div>
    );
}

export default NoteTextComponent;


