import { React, useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../../contexts/globalContext";

import DeleteDocumentPopup from '../popups/DeleteDocumentPopup';

import AddFilesPopup from '../popups/AddFilesPopup';

import ShareDocumentPopup from '../popups/ShareDocumentPopup';
import styles from './DocumentComponent.module.css';


import docs from '../../graphics/document.svg';
import img from '../../graphics/image.svg';  // Corrected the img import
import video from '../../graphics/video.svg';
import excel from '../../graphics/excel.svg';
import pdf from '../../graphics/pdf.svg';


const DocumentComponent = ({ document }) => {

    const [editForm, setEditForm] = useState(false);
    const [deleteForm, setDeleteForm] = useState(false);
    const [shareForm, setShareForm] = useState(false);
    const { currentUser } = useContext(GlobalContext);
    const [iconSrc, setIconSrc] = useState(docs); 

    const isOwner = document.users.length > 0 && document.users[0] === currentUser._id;


    useEffect(() => {
        switch (document.type) {
            case "doc":
                setIconSrc(docs);
                break;
            case "sheet":
                setIconSrc(excel);
                break;
            case "image":
                setIconSrc(img);
                break;
            case "video":
                setIconSrc(video);
                break;
            case "pdf":
                setIconSrc(pdf);
                break;
            default:
                setIconSrc(docs);
                break;
        }
    }, [document.type]);

    const openModalEdit = () => {
        setEditForm(true);
    };

    const openModalDelete = () => {
        setDeleteForm(true);
    };

    const openModalShare = () => {
        setShareForm(true);
    };
    const closeModal = () => {
        if (editForm)
            setEditForm(false);
        else if (deleteForm)
            setDeleteForm(false);
        else
            setShareForm(false)
    };


    return (
        <>
            <li>
                <div href="" className={styles.card}>
                    <img src={iconSrc} className={styles.card__image} alt="" />
                    <div className={styles.card__overlay}>
                        <div className={styles.card__header}>
                            <svg className={styles.card__arc} xmlns="http://www.w3.org/2000/svg"><path /></svg>

                            <div className={styles.card__header} >

                                <span className={styles.card__status}>{document.name}</span>
                            </div>
                        </div>

                        <p className={styles.card__description} >
                            
                                <button onClick={openModalDelete}>Delete</button>
                            
                            <a href={process.env.REACT_APP_CMS_URL + document.path} download={document.name} style={{ textDecoration: 'underline', color: 'black' }}>Download file</a>
                            {isOwner &&
                                <button onClick={openModalShare}>Share</button>
                            }
                        </p>
                    </div>
                </div>
            </li>
            {deleteForm && <DeleteDocumentPopup onClose={closeModal} documentID={document._id} />}
            {editForm && <AddFilesPopup onClose={closeModal} document={document} />}
            {shareForm && <ShareDocumentPopup onClose={closeModal} document={document} />}
        </>
    );
}

export default DocumentComponent;