import { useContext, useEffect } from 'react';
import SocketManager from '../utils/SocketManager';
import HeaderComponent from './HeaderComponent';
import DocumentsComponent from './documents/DocumentsComponent';
import ProjectsComponent from './projects/ProjectsComponent';
import { GlobalContext } from "../contexts/globalContext";

import ProjectEditComponent from "./projects/ProjectEditComponent"
import NoteEditComponent from "./projects/NoteEditComponent"

import styles from "./MainScreenComponent.module.css"

const MainScreenComponent = () => {

    const { setPendingDocuments, isOpenedProjects, setProjects, socketRef, currentUser, showEditProjectComponent, showEditNoteComponent  } = useContext(GlobalContext);

    useEffect(() => {
        socketRef.current.emit("GET_ALL_PROJECTS", currentUser._id, true, null, (projects) => {
            setProjects(projects);
            socketRef.current.emit("ADD_USER_TO_SHARE_NOTIFICATION");            
        });

        socketRef.current.emit("GET_USER_PENDING_DOCUMENTS", { userId: currentUser._id}, (pending_documents) => {
            setPendingDocuments(pending_documents);
        });
    }, []) 

    return (
        <div className={styles.component}>
            <SocketManager />
            {!isOpenedProjects && <DocumentsComponent/>}
            {/* <ProjectsComponent/> */}
            {isOpenedProjects && !showEditProjectComponent && !showEditNoteComponent && <ProjectsComponent/>}
            {showEditProjectComponent && <ProjectEditComponent/>}
            {showEditNoteComponent && <NoteEditComponent/>}
            
            
            <HeaderComponent />
            
            
        </div>
    );
}

export default MainScreenComponent;