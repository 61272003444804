import { useState, useRef, createContext } from "react"
import en from "../langs/en.json"

export const GlobalContext = createContext([{}, () => { }])

export const GlobalProvider = (props) => {
    const socketRef = useRef();
    const [language, setLanguage] = useState(en)
    const [currentUser, setCurrentUser] = useState()

    const [documents, setDocuments] = useState([])
    const [projects, setProjects] = useState([])
    const [pendingDocuments, setPendingDocuments] = useState([])
    const [documentsFilter, setDocumentsFilter] = useState(null)
    const [selectedDocument, setSelectedDocument] = useState(null)
    const [showEditDocPopup, setShowEditDocPopup] = useState(false)
    const [editForm, setEditForm] = useState(false);
    const [deleteForm, setDeleteForm] = useState(false);
    const [shareForm, setShareForm] = useState(false);
    const [acceptForm, setAcceptForm] = useState(false);
    const [logged, setLogged] = useState(false)
    const [isOpenedProjects, setIsOpenedProjects] = useState(true)
    const [showAddProjectCompooent, setShowAddProjectCompooent] = useState(false)

    const [showEditProjectComponent, setShowEditProjectComponent] = useState(false)
    const [showEditNoteComponent, setShowEditNoteComponent] = useState(false)

    const [showAddNotePopup, setShowAddNotePopup] = useState(false)
    

    const [currentNote, setCurrentNote] = useState(null)
    const [currentProject, setCurrentProject] = useState(null)

    
    


    return (
        <GlobalContext.Provider value={{
            socketRef,
            language, setLanguage,
            currentUser, setCurrentUser,
            documents, setDocuments,
            documentsFilter, setDocumentsFilter,
            selectedDocument, setSelectedDocument,
            showEditDocPopup, setShowEditDocPopup,
            pendingDocuments, setPendingDocuments,
            logged, setLogged,
            editForm, setEditForm,
            deleteForm, setDeleteForm,
            shareForm, setShareForm,
            acceptForm, setAcceptForm,
            projects, setProjects,
            isOpenedProjects, setIsOpenedProjects,
            showAddProjectCompooent, setShowAddProjectCompooent,
            showEditProjectComponent, setShowEditProjectComponent,
            showEditNoteComponent, setShowEditNoteComponent,
            currentNote, setCurrentNote,
            currentProject, setCurrentProject,
            showAddNotePopup, setShowAddNotePopup
        }}>
            {props.children}
        </GlobalContext.Provider>
    )
}