import { useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../contexts/globalContext";
import MenuComponent from './MenuComponent'
import Logo from "../graphics/logo.svg"

import AcceptDocumentsPopup from './popups/AcceptDocumentsPopup';

const HeaderComponent = () => {

    const [pendingDocumentsNumber, setPendingDocumentsNumber] = useState(0);

    const { currentUser, pendingDocuments, setProjects, setIsOpenedProjects, socketRef } = useContext(GlobalContext);

    const [acceptForm, setAcceptForm] = useState(false);
    const [title, setTitle] = useState("PROJECTS");

    useEffect(() => {
            setPendingDocumentsNumber(pendingDocuments.length);
    }, [pendingDocuments]) 

    return (
        <div style={{ position:"absolute", width: '100vw', display: 'flex', height:"80px", flexDirection: 'column', boxShadow: "0px 2px 4px #00000030"}}>
            <div style={{width: '100vw', height:"40px"}}>
                <img src={Logo} height={30} style={{margin:"5px"}}/>
                
                <div style={{ position:"absolute", top:"7px", right:"10px"}}>
                    {pendingDocumentsNumber > 0 &&
                        <button onClick={() => setAcceptForm(!acceptForm)} style={{ color: 'white', width: 20, height: 20, border:"none", borderRadius:"50%", right: 100, backgroundColor: "red", marginRight:"10px" }}>
                            {pendingDocumentsNumber}
                        </button>
                    }   
                    <label style={{ color: 'gray', fontWeight: 'bold' }}>{currentUser.name}</label>
                </div>
                
            </div>
            <div style={{width: '100vw', height:"40px", borderTop:"solid #11111122 1px", paddingTop:"5px", display: 'flex', flexDirection: 'row', justifyContent: "flex-start"}}>
                <MenuComponent setTitle={setTitle}></MenuComponent>                
                <label style={{ position:"absolute", top:"45px", right:"20px", color: 'black', fontWeight: 'bold', fontSize:"20px" }}>{title}</label>
            </div>



            
            

            
             {acceptForm && <AcceptDocumentsPopup onClose={()=>setAcceptForm(false)} />} 

        </div>
    );
}

export default HeaderComponent;