import styles from './PopupBaseComponent.module.css';

const PopupBaseComponent = ({ title,  children }) => {
    return (
        <div className={styles.overlay}>
            <div className={styles.modalContent}>
                <h3> {title} </h3>
                {children}
            </div>
        </div>
    );
}

export default PopupBaseComponent;
