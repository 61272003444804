import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from "../../contexts/globalContext";
import PlusIcon from "../../graphics/plus.svg";
import styles from "./ProjectEditComponent.module.css";
import SelectAddPopup from '../popups/project/SelectAddPopup';
import DeleteFromProjectPopup from '../popups/project/DeleteFromProjectPopup'
import AddTopicPopup from '../popups/project/AddTopicsPopup';

const ProjectEditComponent = () => {
    const {
        setShowEditProjectComponent,
        currentProject,
        setCurrentProject,
        socketRef,
        setShowEditNoteComponent,
        setCurrentNote,
    } = useContext(GlobalContext);

    useEffect(() => {
        if (socketRef.current && currentProject._id) {
            socketRef.current.emit("GET_PROJECT_BY_ID", currentProject._id, (project) => {
                setCurrentProject(project);
            });

            return () => {
                // Cleanup if necessary
            };
        }
    }, [currentProject._id, setCurrentProject, socketRef]);

    const [deleteForm, setDeleteForm] = useState(false);
    const [currentElement, setCurrentElement] = useState(null);
    const [currentCode, setCurrentCode] = useState(null);
    const [currentParent, setCurrentParent] = useState(null);
    const [selAddPopup, setSelAddPopup] = useState(false);
    const [addSubtopic, setAddSubtopic] = useState(false);

    const closeModal = () => {
        setDeleteForm(false);
    };

    //open Modals
    const openModal = (currentElement, code, parentId) => {
        setCurrentElement(currentElement);
        setCurrentCode(code);
        setCurrentParent(parentId)
        setDeleteForm(true);
    };

    const openSelectModal = () => {
        setSelAddPopup(true);
    };

    const openAddSubtopicModal = (parentId, code) => {
        setCurrentParent(parentId)
        setCurrentCode(code)
        
        setAddSubtopic(true);
    };

    // CloseModals
    const closeSelectModal = () => {
        setSelAddPopup(false);
    };

    const closeAddSubtopicModal = () => {
        setAddSubtopic(false);
    };


    const capitalizeFirstLetter = (string) => {
        if (!string) return string;
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const downloadFile = (url, filename) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className={styles.component}>
            <label>{currentProject.name}</label>
            <div className={styles.gridContainer}>
                <div className={styles.gridItem}>
                    <h3>Participants</h3>
                    {currentProject.participants && currentProject.participants.map((participant, index) => (
                        <div key={index}>
                            {participant.name}
                            <button style={{ margin: '5px' }} onClick={() => openModal(participant._id, 'PRT', currentProject._id)}>Delete</button>
                        </div>
                    ))}
                </div>
                <div className={styles.gridItem}>
                    <h3>Files</h3>
                    {currentProject.files && currentProject.files.map((file, index) => (
                        <div key={index} className={styles.fileContainer}>
                            {file.docsFile.name}
                            <div>
                                <button style={{ margin: '5px' }} onClick={() => openModal(file._id, 'DOC', currentProject._id)}>Delete</button>
                                <button
                                    style={{ margin: '5px' }}
                                    onClick={() => downloadFile(`${process.env.REACT_APP_CMS_URL}${file.docsFile.path}`, file.docsFile.name)}
                                >
                                    Download
                                </button>
                            </div>
                            <label>Type: {capitalizeFirstLetter(file.docsFile.type)}</label>
                            <label>Added by: {file.addedBy.name}</label>
                        </div>
                    ))}
                </div>
                <div className={styles.gridItem}>
                    <h3>Notes</h3>
                    {currentProject.notes && currentProject.notes.map((note, index) => (
                        <div key={index}>
                            {note.name}
                            <button onClick={() => {
                                setShowEditNoteComponent(true);
                                setShowEditProjectComponent(false);
                                setCurrentNote(note);
                            }}>Edit</button>
                        </div>
                    ))}
                </div>
                <div className={styles.gridItem}>
                    <h3 className={styles.title}>Topics</h3>
                    {currentProject.topics && currentProject.topics.map((topic, index) => (
                        <div key={index} className={styles.topicCard}>
                            <div className={styles.headerTopic}>
                                <span className={styles.topicIcon}>📚</span> {topic.name}
                                <div style={{
                                    left: '30px'
                                }}>
                                    <button onClick={() => openAddSubtopicModal(topic._id, 'STPC')} className={styles.addSubButton}></button>
                                    <button onClick={() => openModal(topic._id, 'TPC', currentProject._id)} className={styles.deleteButton}></button>
                                </div>
                            </div>
                            {topic.subtopics?.map((subtopic) => (
                                <div key={subtopic._id} className={styles.subtopic}>
                                    <span  className={styles.subtopicIcon}>🔖</span> {subtopic.name}
                                    <button onClick={() => openModal(subtopic._id, 'STPC', topic._id)} className={styles.deleteButton}></button>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>

            </div>

            <div className={styles.add_note_button} onClick={openSelectModal}>
                <img src={PlusIcon} width={60} height={60} alt="Select PopUp" />
            </div>

            {deleteForm && (
                <DeleteFromProjectPopup
                    onClose={closeModal}
                    parentId={currentParent}
                    elemId={currentElement}
                    code={currentCode}
                />
            )}

            {selAddPopup && (
                <SelectAddPopup
                    onClose={closeSelectModal}
                    projectId={currentProject._id}
                />
            )}

            {addSubtopic && (
                <AddTopicPopup
                    onClose={closeAddSubtopicModal}
                    parentId={currentParent}
                    onCloseMain={closeAddSubtopicModal}
                    code={currentCode}
                />
            )}
        </div>
    );
};

export default ProjectEditComponent;
