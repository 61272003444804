import { useContext } from 'react';
import { GlobalContext } from "./contexts/globalContext";
import LoginComponent from './components/LoginComponent';
import MainScreenComponent from './components/MainScreenComponent';

function App() {
    
    const { logged } = useContext(GlobalContext);

    return (
        <div style={{width:"100vw", height:"100vh"}}>
            {!logged && <LoginComponent></LoginComponent>}
            {logged && <MainScreenComponent/>}
        </div>
    );
}


export default App;
