import { useRef, useContext, useState, useEffect } from 'react';
import styles from './styles.module.css';
import { GlobalContext } from "../../contexts/globalContext";
import PopupErrorComponent from './commons/PopupErrorComponent';
import "@fontsource/roboto";

const DeleteDocumentPopup = ({ onClose, documentID }) => {
    const { currentUser, socketRef, setDeleteForm, deleteForm } = useContext(GlobalContext);
    const [openError, setOpenError] = useState(false);
    const [text, setText] = useState("");

    const openErrorPopup = (message) => {
        setOpenError(true);
        setText(message);
    };

    const closeModal = () => {
        setOpenError(false);
    };

    const formatProjectNames = (projects) => {
        console.log(projects)
        return projects.map(project => project.name).join(", ");
    };

    const deleteDoc = () => {
        socketRef.current.emit("DELETE_DOCUMENT", { docId: documentID, userId: currentUser._id }, (response, projects) => {
            if (response === '400') {
                const message = `Please delete the file from the following projects first: ${formatProjectNames(projects)}`;
                openErrorPopup(message);
            } else {
                onClose();
            }
        });
    };

    return (
        <div className={styles.modal}>
            <div className={styles.overlay}>
                <div className={styles.modalContent}>
                    <label>Are you sure you want to delete this file?</label>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <button onClick={deleteDoc}>Yes</button>
                        <button onClick={onClose}>Cancel</button>
                    </div>
                </div>
            </div>
            {openError && <PopupErrorComponent onClose={closeModal} text={text} />}
        </div>
    );
};

export default DeleteDocumentPopup;
