import React, { useRef, useState, useContext, useEffect } from 'react';
import { GlobalContext } from "../../contexts/globalContext";
import styles from './styles.module.css';


const AcceptDocumentsPopup = ({ onClose }) => {
    const { socketRef, currentUser, pendingDocuments, setPendingDocuments } = useContext(GlobalContext);

    const handleAccept = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const selectedDocs = formData.getAll('docID[]');
        socketRef.current.emit("ACCEPT_DOCUMENTS", { documentIds: selectedDocs, userId: currentUser._id }, () => {
            socketRef.current.emit("GET_USER_PENDING_DOCUMENTS", { userId: currentUser._id}, (pending_documents) => {
                setPendingDocuments(pending_documents);
            });
        });
        onClose()
    };

    return (
        <div className={styles.modal}>
            <div className={styles.overlay}>
                <div className={styles.modalContent}>
                    <form onSubmit={handleAccept}>
                        <input type="hidden" name="id_document" value={document._id} />
                        <table id="share_docs_table">
                            <thead>
                                <tr>
                                    <th width="25px"></th>
                                    <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pendingDocuments && pendingDocuments.map((doc) => (
                                    <tr key={doc._id} className="gradeX odd" role="row">
                                        <td>
                                            <label className="form-check form-check-custom form-check-solid me-10">
                                                <input className="form-check-input h-20px w-20px" type="checkbox" name="docID[]" value={doc._id} />
                                            </label>
                                        </td>
                                        <td>{doc.name} ({doc.users[0].name})</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button type="submit" className="btn btn-sm btn-primary">
                            Accept Documents
                        </button>
                        <button onClick={onClose} className={styles.btn}> Close </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AcceptDocumentsPopup;
