import { useRef, useContext } from 'react';
import { GlobalContext } from "../../../contexts/globalContext";
import PopupBaseComponent from '../commons/PopupBaseComponent';
import PopupButtonComponent from '../commons/PopupButtonComponent';
import PopupInputComponent from '../commons/PopupInputComponent'

const AddTopicPopup = ({ onClose, onCloseMain, parentId, code }) => {
    const nameRef = useRef();
    const { currentProject, socketRef } = useContext(GlobalContext);

    function handleSave() {
        if (!nameRef.current.value) {
            console.error("Name is required.");
            return;
        }

        if (code === 'TPC') {
            socketRef.current.emit("ADD_EDIT_TOPIC", {
                projectId: currentProject._id, topicId: null, newData: {
                    name: nameRef.current?.value ? nameRef.current?.value : "Default Title",
                    subtopics: []
                }
            }, (response) => {
                console.log(response)
            });
            onClose();
            onCloseMain()
        } else {
            socketRef.current.emit("ADD_EDIT_SUBTOPIC", {
                 topicId: parentId, subtopicId: null, projectId: currentProject._id, newData: {
                    name: nameRef.current?.value ? nameRef.current?.value : "Default Title",
                }
            }, (response) => {
                console.log(response)
            });
            onClose();
            onCloseMain()
        }
    }

    return (
        <PopupBaseComponent title={"Add"}>
            <PopupInputComponent ref={nameRef} placeholder={"Name"}></PopupInputComponent>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <PopupButtonComponent onClick={() => handleSave()} text="SAVE" />
                <PopupButtonComponent onClick={() => onClose()} text="CLOSE" />
            </div>
        </PopupBaseComponent>
    );
}

export default AddTopicPopup;
