import styles from './PopupErrorComponent.module.css'

import "@fontsource/roboto"



const PopupErrorComponent = ({ onClose, text }) => {

    return (
        // <div style={{ position: "absolute", width: '150px', height: '70px', backgroundColor: "#FFFFFFCC", display: 'flex', flexDirection: 'column'}}>
        <div className={styles.modal}>
            <div className={styles.overlay}>
                <div className={styles.modalContent}>
                    <label> {text} </label>
                    <div style={{ display: 'flex', flexDirection: 'center' }}>
                        <button className={styles.btn} onClick={onClose}> OK </button>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default PopupErrorComponent;