import { useRef, useContext } from 'react';
import { GlobalContext } from "../../../contexts/globalContext";
import PopupBaseComponent from '../commons/PopupBaseComponent';
import PopupButtonComponent from '../commons/PopupButtonComponent';
import PopupInputComponent from '../commons/PopupInputComponent'

const AddNotePopup = ({ onClose, onCloseMain }) => {
    const noteNameRef = useRef();
    const { currentProject, socketRef } = useContext(GlobalContext);

    function saveNote() {
        if (!noteNameRef.current.value) {
            console.error("Note name is required.");
            return;
        }

        socketRef.current.emit("ADD_NOTE", { projectID: currentProject._id, noteName: noteNameRef.current.value }, (response) => {
            onClose();
            onCloseMain()
            console.log(response)
        });
    }

    return (
        <PopupBaseComponent title="Add Note">
            <PopupInputComponent ref={noteNameRef} placeholder={"Note xx"}></PopupInputComponent>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <PopupButtonComponent onClick={() => saveNote()} text="SAVE" />
                <PopupButtonComponent onClick={() => onClose()} text="CLOSE" />
            </div>
        </PopupBaseComponent>
    );
}

export default AddNotePopup;
