import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from "../../../contexts/globalContext";
import styles from '../styles.module.css';

const SelectDocumentPopup = ({ onClose, onCloseMain }) => {
    const { currentUser, socketRef, currentProject } = useContext(GlobalContext);
    const [documents, setDocuments] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState([]);

    useEffect(() => {
        // Fetch existing documents
        socketRef.current.emit("GET_PROJECT_USER_DOCUMENTS_TO_ADD", {projectId: currentProject._id, userId: currentUser._id}, (response) => {
            console.log(response)
            setDocuments(response);  // Assuming response contains a files array
        });
    }, [currentUser._id, socketRef]);

    const handleDocumentSelection = (documentId) => {
        setSelectedDocuments((prevSelected) => {
            if (prevSelected.includes(documentId)) {
                return prevSelected.filter(id => id !== documentId);
            } else {
                return [...prevSelected, documentId];
            }
        });
    };

    const handleSave = () => {
        // Send selected documents to parent component or server
        socketRef.current.emit("ADD_EXISTING_PROJECT_DOCUMENTS", {docIds: selectedDocuments, userId: currentUser._id, projectId: currentProject._id})
        onClose();
        onCloseMain();
    };

    return (
        <div className={styles.modal}>
            <div className={styles.overlay}>
                <div className={styles.modalContent}>
                    <h2>Select Documents</h2>
                    <div className={styles.fileList}>
                        {documents.map((document) => (
                            <div key={document._id} className={styles.fileItem}>
                                <input
                                    type="checkbox"
                                    checked={selectedDocuments.includes(document._id)}
                                    onChange={() => handleDocumentSelection(document._id)}
                                />
                                <span>{document.name}</span>
                            </div>
                        ))}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '20px' }}>
                        <button onClick={handleSave} className={styles.btn}>Save</button>
                        <button onClick={onClose} className={styles.btn}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectDocumentPopup;
