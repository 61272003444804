import React, { useRef, useState, useContext, useEffect } from 'react';
import { GlobalContext } from "../../contexts/globalContext";
import styles from './styles.module.css';


const ShareDocumentPopup = ({ document, onClose }) => {
    const [usersMap, setUsersMap] = useState(null);
    const { socketRef } = useContext(GlobalContext);

    useEffect(() => {
        socketRef.current.emit("GET_ALL_USERS", (users) => {
            const filteredUsers = users.filter(user => (
                !document.users.includes(user._id) && !document.pending_users.includes(user._id)
            ));
            setUsersMap(filteredUsers);
        });
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const selectedUsers = formData.getAll('usersID[]');

        socketRef.current.emit("SHARE_DOCUMENT", { userIDs: selectedUsers, documentId: document._id });
        onClose()
    };

    return (
        <div className={styles.modal}>
            <div className={styles.overlay}>
                <div className={styles.modalContent}>
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" name="id_document" value={document._id} />
                        <table id="share_users_table" className="table table-row-bordered gy-5">
                            <thead>
                                <tr>
                                    <th width="25px"></th>
                                    <th>Name</th>
                                    <th>Department</th>
                                    <th>Function</th>
                                </tr>
                            </thead>
                            <tbody>
                                {usersMap && usersMap.map((user) => (
                                    <tr key={user._id} className="gradeX odd" role="row">
                                        <td>
                                            <label className="form-check form-check-custom form-check-solid me-10">
                                                <input className="form-check-input h-20px w-20px" type="checkbox" name="usersID[]" value={user._id} />
                                            </label>
                                        </td>
                                        <td>{user.name}</td>
                                        <td>{user.department?.name}</td>
                                        <td>{user.function?.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            <button type="submit" width= '80px'>
                                Share Document
                            </button>
                            <button onClick={onClose} className={styles.btn}> Close </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ShareDocumentPopup;
