import React, { useContext, useEffect } from 'react';
import { GlobalContext } from "../../contexts/globalContext";
import styles from './ProjectComponent.module.css';
import icon from '../../graphics/project.svg';

const ProjectComponent = ({ project }) => {
    const { setShowEditProjectComponent, setCurrentProject } = useContext(GlobalContext);

    useEffect(() => {
        console.log(project);
    }, [project]); // Adding the dependency array

    function formatDates(stringDate, status) {
        const date = new Date(stringDate)
        if(status === "created")
            return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`
        return `${date.toLocaleDateString('en-RO', { weekday: 'short'})}. ${date.getDate().toString().padStart(2, '0')} ${date.toLocaleDateString('en-RO', {month: 'short'})}, ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
    }

    return (
        <li>
            <div className={styles.card}>
                <img src={icon} className={styles.card__image} alt="Project icon" />
                <div className={styles.card__overlay}>
                    <div className={styles.card__header}>
                        <svg className={styles.card__arc} xmlns="http://www.w3.org/2000/svg"><path /></svg>
                        <span className={styles.card__status}>{project.name}</span>
                        <span className={styles.card__status}>Conferences: {project.nrConferences}</span>
                        <span className={styles.card__status}>Files: {project.nrFiles}</span>
                        <span className={styles.card__status}>Guests: {project.nrGuests}</span>
                        <span className={styles.card__status}>Participants: {project.nrParticipants}</span>
                        <span className={styles.card__status}>Subtopics: {project.nrSubtopics}</span>
                        <span className={styles.card__status}>Topics: {project.nrTopics}</span>
                        <span className={styles.card__status}>Created Date: {formatDates(project.createdDate, "created")}</span>
                        <span className={styles.card__status}>Status: {project.isActive ? 'Active' : 'Inactive'}</span>
                    </div>
                    <p className={styles.card__description}>
                        <button 
                            onClick={() => {
                                setShowEditProjectComponent(true);
                                setCurrentProject(project);
                            }}
                            className={styles.editButton}
                        >
                            Edit
                        </button>
                    </p>
                </div>
            </div>
        </li>
    );
}

export default ProjectComponent;
