import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from "../../../contexts/globalContext";
import SelectDocumentPopup from './SelectDocumentPopup';
import AddFilesPopup from '../AddFilesPopup';
import AddNotePopup from './AddNotePopup';
import SelectParticipantPopup from './AddParticipantPopup';
import AddTopicPopup from './AddTopicsPopup';
import styles from '../styles.module.css';


const SelectAddPopup = ({ onClose }) => {
    const { setShowAddNotePopup, showAddNotePopup, currentProject, setCurrentProject, socketRef } = useContext(GlobalContext);
    const [editForm, setEditForm] = useState(false);
    const [newForm, setNewForm] = useState(false);
    const [openNote, setOpenNote] = useState(false);
    const [openTopic, setOpenTopic] = useState(false);
    const [participantPopup, setParticipantPopup] = useState(false);

    useEffect(() => {
        if (openNote) {
            setShowAddNotePopup(true);
            setOpenNote(false);
        }
    }, [openNote, setShowAddNotePopup]);

    useEffect(() => {
        if (!showAddNotePopup) {
            socketRef.current.emit("GET_PROJECT_BY_ID", currentProject._id, (project) => {
                setCurrentProject(project);
            });
        }
    }, [showAddNotePopup, socketRef, currentProject._id, setCurrentProject]);


    // open Modals
    const handleFileClick = () => {
        setEditForm(true);
    };

    const handleNewsFileClick = () => {
        setNewForm(true);
    };

    const handleNoteClick = () => {
        setOpenNote(true);
    };

    const handleParticipantClick = () => {
        setParticipantPopup(true);
    };

    const handleTopicClick = () => {
        setOpenTopic(true);
    };

    // Close Modals

    const handleClose = () => {
        if (editForm) {
            setEditForm(false);
        } else if (newForm) {
            setNewForm(false);
        } else if (participantPopup) {
            setParticipantPopup(false)
        }
    };


    return (
        <div className={styles.modal}>
            <div className={styles.overlay}>
                <div className={styles.modalContent}>
                    <button onClick={handleFileClick}>Existing File</button>
                    <button onClick={handleNewsFileClick}>New File</button>
                    <button onClick={handleTopicClick}>Topic</button>
                    <button onClick={handleParticipantClick}>Participant</button>
                    <button onClick={handleNoteClick}>Note</button>
                    <button onClick={onClose}>Cancel</button>
                </div>
            </div>

            {showAddNotePopup && <AddNotePopup onClose={() => setShowAddNotePopup(false)} onCloseMain={onClose} />}
            {editForm && <SelectDocumentPopup onClose={handleClose} onCloseMain={onClose} />}
            {newForm && <AddFilesPopup onClose={handleClose} />}
            {participantPopup && <SelectParticipantPopup onClose={handleClose} onCloseMain={onClose} />}
            {openTopic && <AddTopicPopup onClose={handleClose} onCloseMain={onClose} parentId={currentProject._id} code={"TPC"} />}
        </div>
    );
};

export default SelectAddPopup;
