import { useContext, useState, useRef, useEffect } from 'react';
import { GlobalContext } from "../../contexts/globalContext";
import CloseIcon from "../../graphics/close.svg";
import styles from "./NoteEditComponent.module.css"
import NoteTextComponent from "./notes/NoteTextComponent"
import * as iink from 'iink-js';

const NoteEditComponent = () => {
    const { setShowEditNoteComponent, currentNote, socketRef, setShowEditProjectComponent } = useContext(GlobalContext);
    const [currentLine, setCurrentLine] = useState([]);

    const freeHandRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const editorCompRef = useRef(null);
    const editorRef = useRef(null);

    const [newText, setNewText] = useState("");

    useEffect(() => {
        socketRef.current.emit("OPEN_NOTE", currentNote._id);
        editorRef.current = editorCompRef.current;
        editorRef.current = iink.register(editorCompRef.current, {
            recognitionParams: {
                type: 'TEXT',
                protocol: 'WEBSOCKET',
                apiVersion: 'V4',
                server: {
                    scheme: 'https',
                    host: 'webdemoapi.myscript.com',
                    applicationKey: '1463c06b-251c-47b8-ad0b-ba05b9a3bd01',
                    hmacKey: '60ca101a-5e6d-4159-abc5-2efcbecce059',
                },
            },
        });


        editorCompRef.current.addEventListener('exported', (event) => {
            if (event.detail.exports) {
                var recognizedText = event.detail.exports['text/plain'];
                if (recognizedText) {
                    setNewText(recognizedText);
                }
            }
        });
    }, []);

    const Down = (x, y) => {
        setIsDrawing(true);
        setCurrentLine([...currentLine, [x, y]]);
    }

    const Move = (x, y) => {
        if (isDrawing) {
            setCurrentLine([...currentLine, [x, y]]);
        }
    }

    const Up = () => {
        setIsDrawing(false);
        
        var points = currentLine.map(([x, y]) => `${x},${y}`).join(' ');
        socketRef.current.emit("ADD_PATH_TO_NOTE", { noteId: currentNote._id, path: points });
        setCurrentLine([]);
    }

    const onMouseDown = (e) => {
        Down(e.pageX, e.pageY);
    }

    

    

    const onMouseMove = (e) => {
        Move(e.pageX, e.pageY);
    }

    const onMouseUp = (e) => {
        Up();
    }

    const onTouchStart = (e) => {
        Down(e.touches[0].pageX, e.touches[0].pageY);
    }

    const onTouchMove = (e) => {
        Move(e.touches[0].pageX, e.touches[0].pageY);
    }

    const onTouchEnd = () => {
        Up();
    }


    const ClearEditor = () => {
        editorRef.current.clear();
    }

    const CloseNote = () => {
        socketRef.current.emit("CLOSE_NOTE", currentNote._id);
        setShowEditNoteComponent(false);
        setShowEditProjectComponent(true);
    }

    return (
        <div className={styles.component}>
            <svg style={{ width: '100%', height: '100%' }}
                onMouseDown={(e) => onMouseDown(e)}
                onMouseMove={(e) => onMouseMove(e)}
                onMouseUp={(e) => onMouseUp(e)}

                onTouchStart={(e) => onTouchStart(e)}
                onTouchMove={(e) => onTouchMove(e)}
                onTouchEnd={(e) => onTouchEnd(e)}
            >
                <polyline ref={freeHandRef} points={currentLine.map(([x, y]) => `${x},${y}`).join(' ')}
                    style={{ fill: 'none', stroke: '#fbb03b', strokeWidth: 1 }} />

                {currentNote.svg_paths && currentNote.svg_paths.map((svg_path, index) => (
                    <polyline key={index} points={svg_path} style={{ fill: 'none', stroke: '#fbb03b', strokeWidth: 1 }} />
                ))}
            </svg>

            {currentNote.texts && currentNote.texts.map((text) => (
                <NoteTextComponent key={text._id} textComponent={text}></NoteTextComponent>
            ))}

            <NoteTextComponent ClearEditor={ClearEditor} newText={newText}></NoteTextComponent>

            <div className={styles.close_button} onClick={() => CloseNote()} style={{}}>
                <img src={CloseIcon} width={40} height={40} />
            </div>

            <div className={styles.hid}>
                <div ref={editorCompRef} className={styles.editor} touch-action="none">
                </div>
            </div>
        </div>
    );
}

export default NoteEditComponent;
