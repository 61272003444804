import { useRef, useContext, useState, useEffect } from 'react';
import styles from '../styles.module.css';
import { GlobalContext } from "../../../contexts/globalContext";
import PopupErrorComponent from '../commons/PopupErrorComponent';
import "@fontsource/roboto";

const DeleteFromProjectPopup = ({ onClose, parentId, elemId, code }) => {
    const { currentProject, socketRef, setDeleteForm, deleteForm } = useContext(GlobalContext);

    const handleDelete = () => {
        if (code === "DOC") {
            deleteDoc();
        } else if (code === 'PRT') {
            deleteParticipant();
        } else if (code === 'CNF') {
            deleteConference();
        } else if (code === 'TPC') {
            deleteTopic();
        } else if (code === 'STPC') {
            deleteSubTopic();
        }
    };

    const deleteDoc = () => {
        socketRef.current.emit("DELETE_PROJECT_DOCUMENT", parentId, elemId, (response) => {
            onClose();
        });
    };

    const deleteParticipant = () => {
        socketRef.current.emit("DELETE_PARTICIPANT_FROM_PROJECT", { projectId: parentId, participantId: elemId }, (response) => {
            onClose();
        });
    }

    const deleteConference = () => {
        socketRef.current.emit("DELETE_CONFERENCE", elemId, parentId, (response) => {
            onClose();
        });
    }

    const deleteTopic = () => {
        socketRef.current.emit("DELETE_TOPIC", elemId, parentId, (response) => {
            onClose();
        });
    }

    const deleteSubTopic = () => {
        socketRef.current.emit("DELETE_SUBTOPIC", elemId, parentId, currentProject._id, (response) => {
        });
        onClose();
    }

    return (
        <div className={styles.modal}>
            <div className={styles.overlay}>
                <div className={styles.modalContent}>
                    <label>Are you sure you want to delete this element from project?</label>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <button onClick={handleDelete}>Yes</button>
                        <button onClick={onClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteFromProjectPopup;
