import { useRef, useContext, useState, useEffect } from 'react';

import { GlobalContext } from "../contexts/globalContext";
import en from "../langs/en.json"
import ro from "../langs/ro.json"
import { ApiLoginUser } from '../utils/RestServices';

import style from "./LoginComponent.module.css";

const LoginComponent = () => {

    const inputUserNameRef = useRef()
    const inputPasswordRef = useRef()

    const { language, setLanguage, setCurrentUser, setLogged } = useContext(GlobalContext);


    useEffect(() => {
        inputUserNameRef.current.value = "andrei.lucaci@nextfusion.ro";
        inputPasswordRef.current.value = "andrei123";
        setLanguage(en);
    }, [])

    function login() {
        var body = JSON.stringify({
            email: inputUserNameRef.current.value,
            password: inputPasswordRef.current.value

        },);

        ApiLoginUser(body, data => {
            setCurrentUser(data);
            setLogged(true);            
        });
    }

    

    return (
        <div className={style.component}>
            <div className={style.header}>
                <div className={style.languageButton} style={{}} onClick={() => setLanguage(en)}>EN</div>
                <div className={style.languageButton} onClick={() => setLanguage(ro)}>RO</div>
            </div>

            <div className={style.title}>{language.login_page.Welcome}</div>
            
            <div>
                <div>
                    {language.login_page.Login}
                    <input type="text" ref={inputUserNameRef} placeholder={language.login_page.Username}></input>
                    <input type="password" ref={inputPasswordRef} placeholder={language.login_page.Password}></input>
                    <button onClick={login}>{language.login_page.Login}</button>
                </div>
            </div>            
        </div>
    );
}

export default LoginComponent;