

import styles from './PopupInputComponent.module.css';

import { forwardRef } from 'react';


const PopupInputComponent = forwardRef(({ placeholder }, ref) => {

    return (
        <input className={styles.component} ref={ref} placeholder={placeholder}/>
    );
})

export default PopupInputComponent;
